// extracted by mini-css-extract-plugin
export var caseContactFormSection = "b2_k3";
export var caseStudyBackground__lineColor = "b2_k0";
export var caseStudyHead__imageAbsolute = "b2_lc";
export var caseStudyHead__imageWrapper = "b2_kX";
export var caseStudyProjectsSection = "b2_k4";
export var caseStudyQuote__bgLight = "b2_kY";
export var caseStudyQuote__bgRing = "b2_kZ";
export var caseStudySolution__ring = "b2_k8";
export var caseStudyTech = "b2_lb";
export var caseStudy__bgDark = "b2_kV";
export var caseStudy__bgLight = "b2_kT";